<template>
    <div>
        <div class="nav">
            <div class="nav-1" @click="toList">账户与安全</div>
            <div class="nav-icon"></div>
            <div class="nav-2">更改手机号</div>
        </div>
        <CompHeader title="更改手机号" />
        <el-form
            class="form" 
            ref="form"
            :model="form" 
            :rules="rules"
            size="large"
            label-width="120px"
            label-position="left"
            require-asterisk-position="right">
          <el-form-item prop="country_code" required>
            <template #label>
                <div class="form-label">手机号码</div>
            </template>
            <div class="row-box">
                <el-select 
                    v-model="form.country_code"
                    style="width: 160px; margin-right: 10px;">
                    <el-option
                        v-for="item in CountryCodelist"
                        :key="item.country_code"
                        :label="(item.country_name + ' +' + item.country_code)"
                        :value="item.country_code"
                        />
                </el-select>
                <el-form-item prop="phone">
                    <el-input style="width: 510px;" v-model="form.phone" placeholder="请输入手机号" />
                </el-form-item>
            </div>
          </el-form-item>
          <el-form-item prop="verify">
            <template #label>
                <div class="form-label">验证码</div>
            </template>
            <div class="row-box">
                <el-input style="width: 496px;" v-model="form.verify" placeholder="请输入六位数验证码" />
                <div :class="['yzm-btn', {'not-allow': verifying}]" @click="getVerify">{{verifying ? `重新发送(${time})`  :'免费获取短信验证码'}}</div>
            </div>
          </el-form-item>
        </el-form>
        <el-button class="form-btn" type="primary" @click="submit">保存</el-button>
    </div>
</template>

<script>
import CompHeader from "@@/common/header.vue";

import { getCountryCode, updateMobile, sendVcode } from "@api/index"
export default {
    components: {
        CompHeader
    },
    data() {
        return {
            form: {
                country_code: '86',
                phone: '',
                verify: ''
            },
            rules: {
                phone: [
                    {
                        required: true,
                        validator: this.validatePhone,
                        trigger: 'blur',
                    }
                ],
                verify: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur',
                    }
                ]
            },
            CountryCodelist: [],
            verifying: false, // 是否正在发送验证码
            time: 60,
            loading: false,
            error: false,
            message: ''
        }
    },
    created() {
        this.getPhoneCountry()
    },
    methods: {
        submit() {
            if (this.loading) return
            this.loading = true
            this.$refs.form.validate((valid) => {
                if(valid) {
                    updateMobile(this.form).then(res => {
                        this.loading = false
                        if (res.status == 0) {
                            this.$message.success('修改成功！')
                            this.toList()
                        } else {
                            this.$message.error(res.message || '修改失败！')
                        }
                    })
                } else {
                    return
                }
            })
        },
        toList() {
            this.$router.push('/user/security')
        },
        getPhoneCountry() {
            getCountryCode().then(res => {
                if (res.status == 0) {
                    this.CountryCodelist = res.data
                }
            })
        },
        // 获取验证码
        getVerify() {
            if(this.verifying) return
            if (!this.form.phone) {
                return this.$refs.form.validateField('phone')
            }
            sendVcode({
                country_code: this.form.country_code,
                phone: this.form.phone,
                verify_type: 'bind'
            }).then(res => {
                if (res.status == 0) {
                    this.verifying = true
                    const timer = setInterval(() => {
                        this.time --
                        if (this.time <= 0) {
                            clearInterval(timer)
                            this.verifying = false
                            this.time = 60
                        }
                    }, 1000)
                } else {
                    this.error = true
                    this.message = res.message || '手机号输入错误'
                    return this.$refs.form.validateField('phone')
                }
            })
        },
        validatePhone(rule, value, callback){
            if (value == '') {
                callback(new Error('请输入手机号'))
            } else if(this.error) {
                callback(new Error(this.message))
                this.error = false
            } else {
                callback()
            }
        },
    }
}
</script>

<style  lang="scss" scoped>
.nav {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .nav-1 {
    font-size: 16px;
    font-weight: normal;
    color: #7f7f7f;
    cursor: pointer;
  }
  .nav-icon {
    margin: 0 5px;
    width: 16px;
    height: 16px;
    background: url("~@assets/images/orderlist/logoicon.png") center/cover
      no-repeat;
  }
  .nav-2 {
    font-size: 16px;
    color: #222c37;
    font-weight: bold;
  }
}
.form {
    padding-top: 20px;
    .form-label {
        font-size: 16px;
        color: #1A1A1A;
    }
    .row-box {
        display: flex;
        width: 100%;
        .yzm-btn {
            border: 1px solid #B1B1B1;
            border-radius: 4px;
            flex: 1;
            margin-left: 10px;
            font-size: 16px;
            color: #7F7F7F;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            &:hover {
                color: #1A1A1A;
                border-color: #1A1A1A;
            }
            &.not-allow {
                cursor: not-allowed;
                color: #7F7F7F;
                border-color: #7F7F7F;
            }
        }
    }
}
.form-btn {
    float: right;
    cursor: pointer;
}
</style>